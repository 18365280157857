import { useEffect } from 'react';

import Cookies from "js-cookie";
import {signIn} from "next-auth/react";

import { NextPage, useRouter } from '@dxp-next';

const LoginPage: NextPage = () => {
  const { isReady, query } = useRouter();

  useEffect(() => {
    if (!isReady) return;
    const callbackUrl = (query?.returnUrl as string) || '/my-eneco/dashboard';
    const locale = Cookies.get('NEXT_LOCALE') || 'nl';
    signIn('okta', { callbackUrl: callbackUrl }, {language: locale});
  }, [isReady, query]);

  return null;
};

export default LoginPage;
